var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useRef, createContext, } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/hook";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NetworkConnector from "../../services/NetworkConnector";
import { ChevronDownIcon, ChevronUpIcon, EllipsisHorizontalIcon, MagnifyingGlassIcon, } from "@heroicons/react/24/outline";
import Logo from "../../assets/images/v2/logo-home-v2.png";
import AvatarV2 from "../../assets/images/v2/avatar-v2.png";
import FlagForVietNam from "../../assets/images/flag-for-vietnam.png";
import FlagForEn from "../../assets/images/flag-for-en.png";
import CloseIcon from "../../assets/images/new_close.png";
import NotificationDropdown from "./parts/NotificationDropdown";
import ShoppingCartDropdown from "./parts/shopping-cart/ShoppingCart";
import { removeAccessToken, removeRefreshToken, getAccessToken, getItem, removeItem, LANGUAGE_CODE, storeItem, } from "../../utils/LocalStorageUtil";
import { checkRoleUser, filterTabsByPermission, navigateToUrl, } from "../../utils/CommonUtil";
import { logoutWallet } from "api/authenticate";
import { setGlobalKeywork, getGlobalKeywork, getCurrentMenu, setCurrentMenu, } from "../../store/global/slice";
import SidebarNav, { BRAND_ADMIN_MENU_TABS, BRAND_ADMIN_TAB_NAME, GLOBAL_TAB, GLOBAL_TAB_AFFILIATE, MANAGE_TABS, MANAGE_TAB_NAME, STAFF_MENU_TABS, } from "./parts/SidebarNav";
import { DEFAULT_ROLE } from "../../config/index";
import "./header.scss";
import { SHIPPING_ADDRESS, USER_DATA_KEY } from "../../utils/StorageKeys";
import ModalQrCode from "../common/modal/modalQRCode";
import ModalScannerQrCode from "../common/modal/modalScannerQR";
import { useTranslation } from "react-i18next";
import { setDataShoppingCart } from "../../store/shopping-cart/slice";
import { getGlobalCategory } from "api/global-reponsitory";
import { userStorage } from "services";
import { clearProductToCookie } from "services/cart-serivce";
export var MyContext = createContext(null);
var Header = function (props) {
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var user = getItem(USER_DATA_KEY);
    var parseUser = JSON.parse(user);
    var _b = useState(false), openModalQRCode = _b[0], setOpenModalQRCode = _b[1];
    var _c = useState(false), openModalScannerQRCode = _c[0], setOpenModalScannerQRCode = _c[1];
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var location = useLocation();
    var _d = useState(false), isShowDropdownSign = _d[0], setShowDropdownSign = _d[1];
    var _e = useState(""), keywork = _e[0], setKeywork = _e[1];
    var _f = useState("mail"), current = _f[0], setCurrent = _f[1];
    var _g = useState(false), openSidebarNav = _g[0], setOpenSidebarNv = _g[1];
    var _h = useState(""), typeSidebar = _h[0], setTypeSidebar = _h[1];
    var _j = useState(false), collapse = _j[0], setCollapse = _j[1];
    var _k = useState(null), userData = _k[0], setUserData = _k[1];
    var _l = useState(false), showSearchInput = _l[0], setShowSearchInput = _l[1];
    var _m = useState([]), categoryData = _m[0], setCategoryData = _m[1];
    var _o = useState(false), openCategories = _o[0], setOpenCategories = _o[1];
    var language = getItem(LANGUAGE_CODE);
    var _p = useState(language ? language : "vn"), languageType = _p[0], setLanguageType = _p[1];
    var accessToken = getAccessToken();
    // selectors to access state
    var globalKeywork = useAppSelector(getGlobalKeywork);
    var currentMenu = useAppSelector(getCurrentMenu);
    var _q = useState(false), isShowLanguage = _q[0], setShowLanguage = _q[1];
    var useOutsideAlerter = function (ref) {
        useEffect(function () {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowDropdownSign(false);
                    setShowLanguage(false);
                    setOpenCategories(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return function () {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };
    var dropdownSignRef = useRef(null);
    useOutsideAlerter(dropdownSignRef);
    useEffect(function () {
        if (window && window.ethereum) {
            // window.ethereum.on('chainChanged', (networkId: any) => {
            //     onUserLogout();
            // })
            // window.ethereum.on('accountsChanged', (accounts: any) => {
            //     onUserLogout();
            // })
        }
        var userData = userStorage.get();
        if (userData) {
            setUserData(userData);
        }
        NetworkConnector.setNavigate(navigate);
        getCategory();
    }, []);
    useEffect(function () {
        if (globalKeywork && !keywork) {
            setKeywork(globalKeywork);
        }
    }, [globalKeywork]);
    useEffect(function () {
        setCurrent(currentMenu);
    }, [currentMenu]);
    var getCategory = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getGlobalCategory()];
                case 1:
                    result = _a.sent();
                    setCategoryData(result && result.data && result.data.length ? result.data : []);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.log("Error Get Category ====", error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onUserLogout = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, logoutWallet()];
                case 1:
                    _a.sent();
                    removeAccessToken();
                    removeRefreshToken();
                    setKeywork("");
                    dispatch(setGlobalKeywork({ data: "" }));
                    dispatch(setDataShoppingCart({ data: {} }));
                    navigate("/login");
                    setShowDropdownSign(false);
                    userStorage.remove();
                    removeItem(SHIPPING_ADDRESS);
                    clearProductToCookie();
                    return [4 /*yield*/, NetworkConnector.disconnectWallet()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.log("Error Logout ===", error_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleClick = function (event) {
        var isShow = !isShowDropdownSign;
        setShowDropdownSign(isShow);
        // if (isShow) {
        //     const userData = getItem(USER_DATA_KEY);
        //     console.log('userData', userData);
        //     if (userData) {
        //         setUserData(JSON.parse(userData));
        //     }
        // }
    };
    var handleChangeKeyword = function (event) {
        if (event && event.currentTarget) {
            setKeywork(event.currentTarget.value);
        }
    };
    var checkRouteAdmin = function () {
        if (location &&
            (location.pathname.indexOf(BRAND_ADMIN_TAB_NAME.admin) > -1 ||
                location.pathname.indexOf("/admin") > -1)) {
            return true;
        }
        return false;
    };
    var handleKeyDown = function (event) {
        if (event.key === "Enter") {
            dispatch(setGlobalKeywork({ data: keywork }));
            if (location && location.pathname.indexOf("search-result") === -1) {
                onNavigateMenu("/search-result");
            }
        }
    };
    var onClickClearKeywork = function () {
        setKeywork("");
        dispatch(setGlobalKeywork({ data: "" }));
    };
    var redirectToAction = function (url, data) {
        // window.location.replace(url);
        var location = {
            pathname: url,
        };
        var options = {
            id: data,
            isCheck: true,
        };
        if (!data) {
            window.location.replace(url);
        }
        else {
            navigate(location, { state: options });
        }
    };
    var navigateToAction = function (url, categoryId, type, pricingType) {
        var params = [];
        if (categoryId != null) {
            params.push("categoryId=".concat(categoryId));
        }
        if (pricingType != null) {
            params.push("pricingType=".concat(pricingType));
        }
        if (type) {
            params.push("type=".concat(type));
        }
        url = "/" + url + "?" + params.join("&");
        navigateToUrl(url);
    };
    var onNavigateMenu = function (url) {
        var location = {
            pathname: url,
        };
        navigate(location);
    };
    var onClickMenu = function (url, menu) {
        setCurrent(menu);
        setKeywork("");
        dispatch(setCurrentMenu({ data: menu }));
        dispatch(setGlobalKeywork({ data: "" }));
        onNavigateMenu(url);
    };
    var onOpenSidebar = function (type) {
        if (type) {
            setTypeSidebar(type);
            setOpenSidebarNv(true);
        }
    };
    var onCloseSideBar = function () {
        setOpenSidebarNv(false);
    };
    var handleChangeAdminMenuTab = function (event, tab) {
        event.preventDefault();
        if (!tab)
            return;
        if (tab.name == BRAND_ADMIN_TAB_NAME.brandAdmin) {
            setCollapse(!collapse);
        }
        else {
            setShowDropdownSign(false);
            if (tab.name == "logout") {
                onUserLogout("LOGOUT");
            }
            else if (tab.name == "getmyqrcode") {
                setOpenModalQRCode(true);
            }
            else if (tab.name == "scanqr") {
                setOpenModalScannerQRCode(true);
            }
            else {
                if (tab.url) {
                    redirectToAction(tab.url);
                }
            }
        }
    };
    var handleChangeAdminTab = function (tab) {
        if (tab.url) {
            setShowDropdownSign(false);
            redirectToAction(tab.url);
        }
        else {
            if (tab.name == MANAGE_TAB_NAME.manage_qrscanner) {
                setOpenModalScannerQRCode(true);
            }
        }
    };
    var renderRole = function () {
        var role = "";
        if (checkRoleUser(DEFAULT_ROLE.brandExecutive.name)) {
            role = t("common.brand_executive");
        }
        if (checkRoleUser(DEFAULT_ROLE.brandAdmin.name)) {
            role = t("common.brand_admin");
        }
        if (checkRoleUser(DEFAULT_ROLE.superAdmin.name) && checkRouteAdmin()) {
            role = t("common.supper_admin");
        }
        return role;
    };
    var handleDropdownTabs = function () {
        var tabs = [];
        var isCheckRouteAdmin = checkRouteAdmin();
        if (isCheckRouteAdmin) {
            tabs = __spreadArray(__spreadArray(__spreadArray([], filterTabsByPermission(STAFF_MENU_TABS), true), GLOBAL_TAB_AFFILIATE, true), GLOBAL_TAB, true);
        }
        else {
            tabs = __spreadArray(__spreadArray(__spreadArray([], filterTabsByPermission(BRAND_ADMIN_MENU_TABS), true), GLOBAL_TAB_AFFILIATE, true), GLOBAL_TAB, true);
        }
        if (isCheckRouteAdmin) {
            tabs = __spreadArray([], filterTabsByPermission(STAFF_MENU_TABS), true);
        }
        else {
            tabs = __spreadArray([], filterTabsByPermission(BRAND_ADMIN_MENU_TABS), true);
        }
        if (parseUser && parseUser.affiliateStatus === 1) {
            tabs = __spreadArray(__spreadArray([], tabs, true), GLOBAL_TAB, true);
        }
        else {
            tabs = __spreadArray(__spreadArray(__spreadArray([], tabs, true), GLOBAL_TAB_AFFILIATE, true), GLOBAL_TAB, true);
        }
        return tabs;
    };
    var handleImageError = function (event) {
        event.currentTarget.onerror = null;
        event.currentTarget.src =
            "https://d2x9yynu7r2rki.cloudfront.net/aff/avatar-v2.webp";
    };
    var renderAvatar = function (cssStyle) {
        if (userData && userData.avatar) {
            return (React.createElement(Link, { to: BRAND_ADMIN_MENU_TABS[0].url },
                React.createElement("img", { src: userData.avatar, className: "rounded-full object-cover cursor-pointer ".concat(cssStyle), alt: "obranding-avatar-user", loading: "lazy", onError: handleImageError })));
        }
        return (React.createElement(Link, { to: BRAND_ADMIN_MENU_TABS[0].url, className: "text-[#607d8b]" },
            React.createElement("img", { src: AvatarV2, className: "rounded-full object-cover cursor-pointer ".concat(cssStyle), alt: "obranding-avatar-user", loading: "lazy", onError: handleImageError })));
    };
    var renderSmallAvatar = function (cssStyle) {
        if (userData && userData.avatar) {
            return (React.createElement("img", { src: userData.avatar, className: "rounded-full object-cover cursor-pointer ".concat(cssStyle), alt: "obranding-avatar-user", loading: "lazy" }));
        }
        return (React.createElement("img", { src: AvatarV2, className: "rounded-full object-cover cursor-pointer ".concat(cssStyle), alt: "obranding-avatar-user", loading: "lazy" }));
    };
    var handleClickLanguage = function (type) {
        setLanguageType(type);
        storeItem(LANGUAGE_CODE, type);
        i18n === null || i18n === void 0 ? void 0 : i18n.changeLanguage(type);
        setShowLanguage(false);
    };
    var renderBadge = function (tab) {
        var renderElement = 0;
        switch (tab.name) {
            case "voucher":
                renderElement = 2;
                break;
            case "membership":
                renderElement = 3;
                break;
            case "favorite_brand":
                renderElement = 5;
                break;
        }
        return renderElement ? (React.createElement("span", { className: "badge inline-block py-1 px-1.5 mr-2 leading-none text-center whitespace-nowrap align-baseline font-bold text-white rounded ml-2" }, renderElement)) : null;
    };
    var onClickFree = function () {
        navigateToAction("search-result", null, "voucher", "0");
    };
    var handleNavigateToSearchProduct = function () {
        navigate("/search-items");
    };
    var languageList = [
        {
            value: "vn",
            label: "Tiếng Việt",
        },
        {
            value: "en",
            label: "English",
        },
    ];
    var handleShowLanguage = function () {
        setShowLanguage(!isShowLanguage);
    };
    return (React.createElement(MyContext.Provider, { value: {
            setOpenModalQRCode: function (value) { return setOpenModalQRCode(value); },
            setOpenModalScannerQRCode: function (value) {
                return setOpenModalScannerQRCode(value);
            },
        } },
        !openSidebarNav ? (React.createElement("nav", { className: "\n                header-container\n                relative\n                w-full\n                flex flex-wrap\n                items-center\n                justify-between\n                text-gray-500\n                hover:text-gray-700\n                focus:text-gray-700\n                navbar navbar-expand-lg navbar-light\n                " },
            React.createElement("div", { className: "relative container-fluid w-full flex items-center justify-between px-16 py-3 max-sm:px-4 max-sm:py-2 max-lg:px-6" },
                React.createElement("div", { className: "flex items-center mx-3 max-sm:mx-0 sm:hidden ".concat(checkRouteAdmin() ? "hidden" : "") }, showSearchInput && (React.createElement("img", { className: "w-8 h-8 max-sm:w-6 max-sm:h-6", src: CloseIcon, alt: "obranding-close-icon", onClick: function () { return setShowSearchInput(false); } }))),
                showSearchInput ? (React.createElement("input", { type: "search", id: "default-search", value: keywork, onChange: handleChangeKeyword, onKeyDown: handleKeyDown, className: "search w-full px-4 py-2 text-sm text-gray-900 border-0 border-gray-300 rounded-full focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500", placeholder: "".concat(t("common.search")), required: true })) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "navbar-collapse w-full flex items-center justify-between", id: "navbarSupportedContent" },
                        React.createElement(Link, { to: "/", onClick: function (event) { return onClickMenu("/", "HOME"); }, className: "flex items-center text-gray-900 hover:text-gray-900 focus:text-gray-900 lg:mt-0 max-[500px]:mr-0" },
                            React.createElement("img", { src: Logo, className: "w-32 h-full max-sm:w-[46px] max-sm:h-[unset] ".concat(checkRouteAdmin()
                                    ? "max-w-[60%] max-sm:max-w-[unset]"
                                    : ""), alt: "obranding logo", width: "165px", height: "78px", loading: "lazy" })),
                        !checkRouteAdmin() ? (React.createElement("div", { className: "w-full mx-10 max-sm:mx-0 max-sm:w-0 flex flex-col gap-3" },
                            React.createElement("div", { className: "hidden sm:flex w-full" },
                                React.createElement("label", { htmlFor: "default-search", className: "mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white" }, "Search"),
                                React.createElement("div", { className: "relative w-full" },
                                    React.createElement("div", { className: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" },
                                        React.createElement("svg", { "aria-hidden": "true", className: "w-5 max-sm:w-[18px] h-5 max-sm:h-[18px]  text-gray-500 dark:text-gray-400", fill: "none", stroke: "currentColor", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" },
                                            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" }))),
                                    React.createElement("input", { type: "search", id: "default-search", value: keywork, onChange: handleChangeKeyword, onKeyDown: handleKeyDown, className: "header-searchbox-bar w-full h-10 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-full focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500", placeholder: "".concat(t("common.search")), required: true }))),
                            React.createElement("div", { className: "w-full" },
                                React.createElement("ul", { className: "navbar-nav hidden lg:flex md:justify-center md:items-center pl-0 list-none text-base m-0 gap-8" },
                                    React.createElement("li", { className: "nav-item" },
                                        React.createElement(Link, { to: "/about-obranding", onClick: function () {
                                                return onClickMenu("/about-obranding", "ABOUT_OBRANDING");
                                            }, className: "menu-item nav-link no-underline text-neutralPrimary uppercase text-sm p-0 ".concat(current === "ABOUT_OBRANDING"
                                                ? "menu-active"
                                                : "!font-normal") }, t("menus.about_obranding"))),
                                    React.createElement("li", { className: "nav-item menu-sanh" },
                                        React.createElement(Link, { to: "/sanh-card", onClick: function () {
                                                return onClickMenu("/sanh-card", "OBRANDING_CARD");
                                            }, className: "menu-item nav-link no-underline uppercase text-sm p-0 !font-bold" }, t("menus.obranding_card"))),
                                    React.createElement("li", { className: "nav-item" },
                                        React.createElement(Link, { to: "/partners", onClick: function () {
                                                return onClickMenu("/partners", "BRAND_PARTNER");
                                            }, className: "menu-item nav-link no-underline text-neutralPrimary uppercase text-sm p-0 ".concat(current === "BRAND_PARTNER"
                                                ? "menu-active"
                                                : "!font-normal") }, t("menus.brand_partner"))),
                                    React.createElement("li", { className: "nav-item" },
                                        React.createElement(Link, { to: "/digital-product", onClick: function () {
                                                return onClickMenu("/digital-product", "DIGITAL_PRODUCT");
                                            }, className: "menu-item nav-link no-underline text-neutralPrimary uppercase text-sm p-0 ".concat(current === "DIGITAL_PRODUCT"
                                                ? "menu-active"
                                                : "!font-normal") }, t("menus.digital_product"))))))) : null),
                    React.createElement("div", { className: "items-center relative" },
                        React.createElement("div", { className: "flex items-center justify-end" },
                            React.createElement("div", { className: "flex items-center mr-5 max-[500px]:mr-0 max-[500px]:ml-2 sm:hidden ".concat(checkRouteAdmin() ? "hidden" : "") }, !showSearchInput && (React.createElement(MagnifyingGlassIcon, { className: "w-6 h-6 text-neutralPrimary", onClick: function () { return setShowSearchInput(!showSearchInput); } }))),
                            React.createElement("div", { className: "max-md:hidden mr-4 cursor-pointer ".concat(checkRouteAdmin() || accessToken ? "hidden" : ""), onClick: handleNavigateToSearchProduct },
                                React.createElement("span", { className: "text-sm font-medium text-brand" }, t('home_page.find_your_product'))),
                            React.createElement("div", { className: "dropdown max-[500px]:ml-4 ".concat(checkRouteAdmin() ? "hidden" : "") },
                                React.createElement(ShoppingCartDropdown, null)),
                            React.createElement("div", { className: "dropdown ".concat(checkRouteAdmin() || !accessToken ? "hidden" : "") },
                                React.createElement(NotificationDropdown, null)),
                            React.createElement("div", { className: "".concat(accessToken && "mr-7", " relative max-sm:!hidden") },
                                React.createElement("div", { className: "flex items-center" },
                                    React.createElement("img", { onClick: handleShowLanguage, src: languageType == "vn" ? FlagForVietNam : FlagForEn, className: "rounded-full object-cover cursor-pointer w-6 h-6", alt: "obranding-flag-vietnam", loading: "lazy" })),
                                isShowLanguage ? (React.createElement("div", { className: "dropdown-filter-container pt-3 px-6 absolute top-6 right-0", ref: dropdownSignRef }, languageList && languageList.length
                                    ? languageList.map(function (languagedata) { return (React.createElement("div", { key: languagedata.value, className: "title-l flex flex-row items-center", onClick: function () {
                                            return handleClickLanguage(languagedata.value);
                                        } },
                                        React.createElement("span", { className: "my-2 cursor-pointer hover:text-brand whitespace-nowrap" }, languagedata.label))); })
                                    : null)) : ("")),
                            React.createElement("div", { className: "dropdown relative dropdowns-wrapper" },
                                React.createElement("div", { className: "dropdown-container", ref: dropdownSignRef },
                                    React.createElement("div", { className: "notifications dropdown dd-trigger" },
                                        React.createElement("a", { className: "\n                                                    hidden\n                                                    cursor-pointer\n                                                    text-gray-500\n                                                    hover:text-gray-700\n                                                    focus:text-gray-700\n                                                    max-[500px]:mr-0\n                                                    dropdown-toggle\n                                                    hidden-arrow\n                                                    md:flex items-center\n                                                ", onClick: handleClick, role: "button", "aria-expanded": "false" }, accessToken
                                            ? renderSmallAvatar("w-6 h-6 text-white stroke-2")
                                            : null),
                                        React.createElement("a", { className: "\n                                                    cursor-pointer\n                                                    text-gray-500\n                                                    hover:text-gray-700\n                                                    focus:text-gray-700\n                                                    mr-4\n                                                    max-[500px]:mr-0\n                                                    dropdown-toggle\n                                                    hidden-arrow\n                                                    flex items-center\n                                                    md:hidden\n                                                ", onClick: function () { return onOpenSidebar("PROFILE"); }, id: "dropdownMenuButton2", role: "button", "aria-expanded": "false" }, accessToken ? (renderSmallAvatar("w-6 h-6 text-white")) : (React.createElement("img", { src: AvatarV2, className: "w-6 h-6", alt: "obranding-avatar-user", loading: "lazy" })))),
                                    isShowDropdownSign ? (React.createElement("div", { className: "custom-select-dropdown custom-select-dropdown-profile custom-select-sign arrow ".concat(!accessToken ? "dropdown-profile-unset" : ""), style: { position: "absolute", zIndex: 99999999 } },
                                        React.createElement("div", { className: "dropdown-body", id: "scrollableNotification" }, accessToken ? (React.createElement("div", { key: 1, className: "notification" },
                                            React.createElement("div", { className: "w-full px-2 flex flex-row items-start mb-4" },
                                                renderAvatar("w-16 h-16 cursor-pointer"),
                                                React.createElement("div", { className: "description-avatar ml-3 flex flex-col items-start" },
                                                    React.createElement("h4", { className: "m-0" }, userData && userData.displayName
                                                        ? userData.displayName
                                                        : t("common.not_display_name")),
                                                    React.createElement("p", { className: "title body-l m-0" }, renderRole()))),
                                            (handleDropdownTabs() || []).map(function (tab) { return (React.createElement(React.Fragment, null,
                                                tab.name !==
                                                    BRAND_ADMIN_TAB_NAME.brandAdmin && (React.createElement("div", { className: "memberships-filter-item-content-menu memberships-filter-item-content-profile cursor-pointer", key: tab.id },
                                                    React.createElement("p", { className: "memberships-filter-item my-0 flex flex-row justify-between items-center ".concat(tab.name == "logout"
                                                            ? "logout-title-noborder"
                                                            : ""), onClick: function (event) {
                                                            return handleChangeAdminMenuTab(event, tab);
                                                        } },
                                                        React.createElement(Link, { to: tab === null || tab === void 0 ? void 0 : tab.url, className: "no-underline text-primary-header font-semibold" },
                                                            React.createElement("p", { className: "my-0 ".concat(tab.name == "logout"
                                                                    ? "logout-title"
                                                                    : "") }, tab.translateText
                                                                ? t(tab.translateText)
                                                                : tab.label))))),
                                                tab.name ===
                                                    BRAND_ADMIN_TAB_NAME.brandAdmin && (React.createElement("div", { className: "memberships-filter-item-content-menu memberships-filter-item-content-profile" },
                                                    React.createElement("div", { className: "memberships-filter-item text-primary-header my-0 cursor-pointer", onClick: function (event) {
                                                            return handleChangeAdminMenuTab(event, tab);
                                                        } },
                                                        React.createElement(Link, { to: tab === null || tab === void 0 ? void 0 : tab.url, className: "no-underline text-primary-header flex flex-row justify-between items-center" },
                                                            React.createElement("p", { className: "my-0" }, tab.translateText
                                                                ? t(tab.translateText)
                                                                : tab.label),
                                                            React.createElement("div", { className: "memberships-filter-item-icon" }, collapse ? (React.createElement(ChevronDownIcon, { className: "icon-outline h-5 w-5 pr-2 cursor-pointer" })) : (React.createElement(ChevronUpIcon, { className: "icon-outline h-5 w-5 pr-2 cursor-pointer" }))))),
                                                    collapse &&
                                                        filterTabsByPermission(MANAGE_TABS).map(function (tab) { return (React.createElement("div", { className: "admin-sub-tabs ml-5 cursor-pointer py-1", key: tab.id, onClick: function () {
                                                                return handleChangeAdminTab(tab);
                                                            } },
                                                            React.createElement(Link, { to: tab === null || tab === void 0 ? void 0 : tab.url, className: "no-underline text-primary-header block" }, tab.translateText
                                                                ? t(tab.translateText)
                                                                : tab.label))); }))))); }))) : (React.createElement(React.Fragment, null,
                                            React.createElement("div", { key: 2, className: "notification cursor-pointer !p-0" },
                                                React.createElement("div", { className: "notification-text !p-0" },
                                                    React.createElement(Link, { to: "/login", className: "no-underline block text-[#747f8b] px-7 py-4" },
                                                        React.createElement("p", { className: "text-base my-0" }, t("common.sign_in"))))),
                                            React.createElement("div", { key: 3, className: "notification cursor-pointer !p-0" },
                                                React.createElement("div", { className: "notification-text !p-0" },
                                                    React.createElement(Link, { to: "/signup", className: "no-underline block text-[#747f8b] px-7 py-4" },
                                                        React.createElement("p", { className: "text-base my-0" }, t("common.sign_up")))))))))) : (""))),
                            React.createElement("div", { className: "flex lg:hidden ml-4 max-[500px]:mr-0 ".concat(checkRouteAdmin() ? "hidden" : "") },
                                React.createElement(EllipsisHorizontalIcon, { className: "w-6 h-6 text-neutralPrimary", onClick: function () { return onOpenSidebar("GENERAL"); } }))),
                        React.createElement("div", { className: "flex mt-3 max-sm:hidden gap-2 flex-1 h-[30px] ".concat(checkRouteAdmin() ? "hidden" : "") }, !accessToken ? (React.createElement(React.Fragment, null,
                            React.createElement("button", { onClick: function () {
                                    navigate("/login");
                                }, type: "button", className: "load-more-btn !w-28 !min-w-24 !my-0 py-[5px] px-2 rounded-[100px] cursor-pointer" },
                                React.createElement("span", { className: "text-white font-sans font-medium text-sm" }, t("common.sign_in"))),
                            React.createElement("button", { onClick: function () {
                                    navigate("/signup");
                                }, type: "button", className: "border-none !w-28 !min-w-24 !my-0 py-[5px] px-2 rounded-[100px] cursor-pointer !bg-lightGrey" },
                                React.createElement("span", { className: "text-neutralPrimary font-sans font-medium text-sm" }, t("common.sign_up"))))) : null))))))) : (React.createElement(SidebarNav, { languages: languageList, categories: categoryData, onCloseSideBar: onCloseSideBar, isRouteAdmin: checkRouteAdmin(), handleDropdownTabs: handleDropdownTabs, type: typeSidebar, onLogout: onUserLogout })),
        openModalQRCode && React.createElement(ModalQrCode, { openModalQRCode: openModalQRCode }),
        openModalScannerQRCode && React.createElement(ModalScannerQrCode, null)));
};
export default Header;
