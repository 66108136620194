var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { apiClient } from "api";
import { handleResponse, redirectToLogin } from "utils/ApiResponse";
import { ProductTypeEnum } from "utils/CommonUtil";
import { getItem } from "utils/LocalStorageUtil";
import { TOKEN_KEY } from "utils/StorageKeys";
var membershipUrl = "membership";
export var getMembershipList = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(membershipUrl, "/featured"), {
                        params: params,
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_1 = _a.sent();
                return [2 /*return*/, Promise.reject(error_1)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getMembershipsByBrand = function (params, pricingType) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                url = "/".concat(membershipUrl, "/bybrand?page=").concat(params.page, "&size=").concat(params.size);
                if (params.brandId) {
                    url = "".concat(url, "&brandId=").concat(params.brandId, "&pricingType=").concat(pricingType);
                }
                if (params.campaignId) {
                    url = "".concat(url, "&campaignId=").concat(params.campaignId);
                }
                return [4 /*yield*/, apiClient.get(url)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_2 = _a.sent();
                return [2 /*return*/, Promise.reject(error_2)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getBrowseMembershipList = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(membershipUrl, "/browse"), { params: params })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_3 = _a.sent();
                return [2 /*return*/, Promise.reject(error_3)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getFreeGiftProducts = function (path, params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(path, "/browse"), { params: params })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_4 = _a.sent();
                return [2 /*return*/, Promise.reject(error_4)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getMembershipDetail = function (params, accesstoken) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(membershipUrl, "/").concat(params))];
            case 1:
                response = _a.sent();
                // console.log(">>> getMembershipDetail response", response.data);
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_5 = _a.sent();
                return [2 /*return*/, Promise.reject(error_5)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getPurchasedMembershipDetail = function (id, params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(membershipUrl, "/").concat(id, "/nftBlocks"), {
                        params: params,
                    })];
            case 1:
                response = _a.sent();
                // console.log(">>> getPurchasedMembershipDetail response", response.data);
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_6 = _a.sent();
                return [2 /*return*/, Promise.reject(error_6)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getMyMembershipList = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(membershipUrl, "/mylist"), { params: params })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_7 = _a.sent();
                return [2 /*return*/, Promise.reject(error_7)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var mintMembershipCard = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(membershipUrl, "/mint"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_8 = _a.sent();
                return [2 /*return*/, Promise.reject(error_8)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var sendMembershipCard = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(membershipUrl, "/send"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_9 = _a.sent();
                return [2 /*return*/, Promise.reject(error_9)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getMembershipCode = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(membershipUrl, "/getCode/").concat(id))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_10 = _a.sent();
                return [2 /*return*/, Promise.reject(error_10)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getMembershipPrivateCode = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(membershipUrl, "/qrCode/generate/").concat(id))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_11 = _a.sent();
                return [2 /*return*/, Promise.reject(error_11)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var applyMembership = function (id, body) { return __awaiter(void 0, void 0, void 0, function () {
    var token, response, error_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, getItem(TOKEN_KEY)];
            case 1:
                token = _a.sent();
                if (!token) {
                    redirectToLogin();
                }
                return [4 /*yield*/, apiClient.post("".concat(membershipUrl, "/apply/").concat(id), body)];
            case 2:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 3:
                error_12 = _a.sent();
                return [2 /*return*/, Promise.reject(error_12)];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var updateMembershipStatus = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_13;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(membershipUrl, "/updatestatus/"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_13 = _a.sent();
                return [2 /*return*/, Promise.reject(error_13)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getMembershipsRequests = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_14;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(membershipUrl, "/bybrandadmin/userrequest"), { params: params })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_14 = _a.sent();
                return [2 /*return*/, Promise.reject(error_14)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var previewMembershipCSVFile = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_15;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(membershipUrl, "/previewCSV"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_15 = _a.sent();
                return [2 /*return*/, Promise.reject(error_15)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var createMembership = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_16;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(membershipUrl), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_16 = _a.sent();
                return [2 /*return*/, Promise.reject(error_16)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var editMembership = function (id, body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_17;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.put("".concat(membershipUrl, "/").concat(id), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_17 = _a.sent();
                return [2 /*return*/, Promise.reject(error_17)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getMembershipById = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_18;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(membershipUrl, "/").concat(id))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_18 = _a.sent();
                return [2 /*return*/, Promise.reject(error_18)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var previewMembershipCSVStatusFile = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_19;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(membershipUrl, "/previewCodeStatus"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_19 = _a.sent();
                return [2 /*return*/, Promise.reject(error_19)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var syncStatusMembership = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_20;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(membershipUrl, "/syncCodeStatus"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_20 = _a.sent();
                return [2 /*return*/, Promise.reject(error_20)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getExcelBlockMembership = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_21;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(membershipUrl, "/excel/membershipBlock"), {
                        params: params,
                        responseType: "arraybuffer",
                        headers: {
                            "Content-Disposition": 'attachment; filename="example.xlsx"',
                        },
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_21 = _a.sent();
                return [2 /*return*/, Promise.reject(error_21)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getExcelBlockMembershipByAdmin = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_22;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("admin/".concat(membershipUrl, "/excel/membershipBlock"), {
                        params: params,
                        responseType: "arraybuffer",
                        headers: {
                            "Content-Disposition": 'attachment; filename="example.xlsx"',
                        },
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_22 = _a.sent();
                return [2 /*return*/, Promise.reject(error_22)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var scanQRMembership = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_23;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(membershipUrl, "/qrCode/scan"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_23 = _a.sent();
                return [2 /*return*/, Promise.reject(error_23)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var approveScanQRMembership = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_24;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(membershipUrl, "/qrCode/approve"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_24 = _a.sent();
                return [2 /*return*/, Promise.reject(error_24)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var activeBlock = function (type, body) { return __awaiter(void 0, void 0, void 0, function () {
    var PRODUCT_TYPE, response, error_25;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                PRODUCT_TYPE = (_a = {},
                    _a[ProductTypeEnum.Voucher] = "voucher",
                    _a[ProductTypeEnum.Membership] = "membership",
                    _a[ProductTypeEnum.Fan] = "fan",
                    _a);
                return [4 /*yield*/, apiClient.post("".concat(PRODUCT_TYPE[type], "/activation"), body)];
            case 1:
                response = _b.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_25 = _b.sent();
                return [2 /*return*/, Promise.reject(error_25)];
            case 3: return [2 /*return*/];
        }
    });
}); };
